export const initGoogleTagManager = (GTM_ID: string) => {
  const script = `
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', '${GTM_ID}')`

  const iframeElement = () => {
    const element = document.createElement('noscript')
    const iframe = document.createElement('iframe')
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`
    iframe.height = '0'
    iframe.width = '0'
    iframe.style.display = 'none'
    iframe.style.visibility = 'hidden'
    element.appendChild(iframe)
    return element
  }

  const scriptElement = () => {
    const element = document.createElement('script')
    element.innerHTML = script
    return element
  }

  document.head.insertBefore(scriptElement(), document.head.childNodes[0])
  document.body.insertBefore(iframeElement(), document.body.childNodes[0])
}
