export interface StudyTimeEntity {
  classId: string
  weeks: {
    monday: string
    sunday: string
  }[]
  studyTimes: StudyTime[]
}

export interface StudyTime {
  userId: string
  firstName: string
  lastName: string
  timeSpentGoal: number
  weeks: {
    week: {
      monday: string
      sunday: string
    }
    timeSpent?: number
    beforeCreationTime?: boolean
  }[]
}

export interface StudyTimeState {
  entities: StudyTimeEntity[]
}

export enum STUDY_TIME_ACTIONS {
  LOAD_STUDY_TIME_BY_CLASS_ID = 'LOAD_TIME_STUDIED'
}

// ACTIONS

export interface LoadStudyTimeByClassIdAction {
  type: STUDY_TIME_ACTIONS.LOAD_STUDY_TIME_BY_CLASS_ID
  entity: StudyTimeEntity
}

export type StudyTimeAction = LoadStudyTimeByClassIdAction
