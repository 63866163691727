export interface Paginated<T> {
  results: Array<T>
  status: PaginatedStatus
}

export interface PaginatedParams {
  limit?: number
  page?: number
  sort?: ApiSortModel
  search?: string
  filter?: ApiFilterModel
}

export interface PaginatedStatus {
  total: number
  offset: number
}

export type ApiSortModel = { [key: string]: 1 | -1 }

export type ApiFilterModel = { [key: string]: string | string[] }

export enum ApiReqState {
  IDLE,
  PENDING,
  RESOLVED,
  REJECTED,
  FINISHED
}
