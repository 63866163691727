import { ApiReqState } from 'shared/api/types'

interface StatisticDetails {
  userGoal: number
  userScore: number
}

interface StatisticEntity {
  classId: string
  statistics: Statistic[]
}

export interface Statistic {
  _id: string
  firstName: string
  lastName: string
  progress: {
    fluency: StatisticDetails
    comprehension: StatisticDetails
  }
  effort: {
    timeSpent: StatisticDetails
    booksCompleted: StatisticDetails
    exercisesCompleted: StatisticDetails
  }
}

export interface StudentGoals {
  _id: string
  fluency?: number
  comprehension?: number
  timeSpent?: number
  booksCompleted?: number
  exercisesCompleted?: number
}

export interface StatisticsState {
  entities: StatisticEntity[]
  reqState: ApiReqState
}

export enum STATISTICS_ACTIONS {
  LOAD_STATISTICS_BY_CLASS_ID = 'LOAD_STATISTICS',
  LOAD_STATISTICS_BY_CLASS_ID_REQ_STATE = 'LOAD_STATISTICS_REQ_STATE'
}

// ACTIONS

export interface LoadStatisticsByClassIdAction {
  type: STATISTICS_ACTIONS.LOAD_STATISTICS_BY_CLASS_ID
  entity: StatisticEntity
}

export interface LoadStatisticsByClassIdAction {
  type: STATISTICS_ACTIONS.LOAD_STATISTICS_BY_CLASS_ID
  entity: StatisticEntity
}

export interface LoadStatisticsByClassIdReqStateAction {
  type: STATISTICS_ACTIONS.LOAD_STATISTICS_BY_CLASS_ID_REQ_STATE
  reqState: ApiReqState
}

export type StatisticAction = LoadStatisticsByClassIdAction | LoadStatisticsByClassIdReqStateAction
